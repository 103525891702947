import Equipment from '@/libs/classes/equipment';
import Device from '@/libs/classes/device';

export default {
  props: {
    equipment: {
      type: Equipment,
      default: null,
    },
    device: {
      type: Device,
      default: null,
    },
  },
};
