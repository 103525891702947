<template>
  <div class="container position-relative">
    <!-- begin::Loading -->
    <template v-if="isMounted === false || isLoading">
      <div class="loading-container loading-overlap">
        <div class="loading-backdrop rounded" />
        <div class="loading-block">
          <div class="blockui ml-auto mr-auto w-100">
            <span class="float-left">Gathering data...</span>
            <span class="spinner spinner-primary ml-auto" />
          </div>
        </div>
      </div>
    </template>
    <!-- end::Loading -->

    <!-- begin::Row - dropdown -->
    <div :class="['row', { 'border-bottom pb-3': log_options && log_options.length }]">
      <div class="col-12 mt-3 order-1 col-lg-3 mt-lg-0 order-lg-0">
        <b-dropdown
          ref="log-actions"
          variant="outline-secondary btn-block"
          class="dropdown-inline w-100 w-lg-auto"
          no-caret
          menu-class="dropdown-menu-md"
          boundary="window"
          :disabled="isMounted === false || isLoading"
        >
          <template v-slot:button-content>
            <i class="svg-icon">
              <font-awesome-icon :icon="['fas', 'cog']" />
            </i>

            <span>Actions</span>
          </template>

          <template v-slot:default>
            <ul class="navi flex-column navi-hover py-2">
              <li class="navi-header font-weight-bolder text-uppercase font-size-xs text-primary pb-2">
                Choose an action:
              </li>

              <li class="navi-separator my-0" />

              <li class="navi-item">
                <a class="navi-link cursor-pointer" @click.prevent="downloadLogFile(log, { query: { format: 'csv' } })">
                  <span class="navi-icon">
                    <i>
                      <font-awesome-icon :icon="['fas', 'file-csv']" class="w-15px h-15px" />
                    </i>
                  </span>
                  <span class="navi-text">Download CSV</span>
                </a>
              </li>

              <li class="navi-item">
                <a class="navi-link cursor-pointer" @click.prevent="downloadLogFile(log, { query: { format: 'json' } })">
                  <span class="navi-icon">
                    <i>
                      <font-awesome-icon :icon="['fas', 'file-code']" class="w-15px h-15px" />
                    </i>
                  </span>
                  <span class="navi-text">Download JSON</span>
                </a>
              </li>
            </ul>
          </template>
        </b-dropdown>
      </div>

      <div class="col-12 col-lg-6 order-0">
        <div class="input-group">
          <div class="input-group-prepend">
            <button
              type="button"
              class="btn btn-light"
              :disabled="!log_options || log_options.length === 0 || (log && log._meta.guid === log_options[log_options.length - 1]._meta.guid)"

              @click.prevent="selectPreviousLog()"
            >
              <font-awesome-icon :icon="['fas', 'chevron-left']" />
            </button>
          </div>
          <select
            class="form-control border-left border-right"
            @change.prevent="onLogSelect($event.target.value)"
          >
            <option v-if="!log_options || log_options.length === 0" disabled selected>No logs found</option>

            <template v-else>
              <option v-if="isErrorActive" :selected="isErrorActive" disabled>Log not found</option>

              <option
                v-for="log in log_options"
                :key="`equipment.${equipment._meta.guid}.logs.boot.${log._meta.guid}`"
                :value="log._meta.guid"
                :selected="$data.log && log._meta.guid === $data.log._meta.guid"
              >
                {{ $moment.utc(log.log_date).tz(equipment.location.timezone || 'UTC').format('HH:mm [on] DD MMMM YYYY') }} (#{{ log.log_id }} - {{ log.controller_serial }})
              </option>
            </template>
          </select>
          <div class="input-group-append">
            <button
              type="button"
              class="btn btn-light"
              :disabled="!log_options || log_options.length === 0 || (log && log._meta.guid === log_options[0]._meta.guid)"

              @click.prevent="selectNextLog()"
            >
              <font-awesome-icon :icon="['fas', 'chevron-right']" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- end::Row - dropdown -->

    <!-- begin::Error -->
    <div v-if="isErrorActive" class="row mt-3 px-5">
      <error-translation-alert
        ref="errorAlert"
        :error="activeError"
        class="col"
      />
    </div>
    <!-- end::Error -->

    <!-- begin::Content -->
    <div v-if="log" class="row mt-3">
      <div class="col">
        <!-- begin::Modules - Standard -->
        <div class="row border-bottom">
          <div class="col-12 font-weight-bold mb-3">Standard</div>
          <div
            v-for="module in modules.standard"
            :key="`equipment.${equipment._meta.guid}.log.${log._meta.guid}.module.primary.${module.module}`"
            class="col-12 col-md-6 col-lg-4"
          >
            <div class="form-group">
              <label class="text-upper">{{ $tu(`luxura_logs.boot.modules.${module.module}.equipment.${equipment.model}.name`) || $tu(`luxura_logs.boot.modules.${module.module}.name`) || module.module }}</label>
              <div>
                <div class="overflow-ellipsis">
                  <template v-if="log.isModulePresent(module.module)">
                    <h5 class="d-inline align-middle">
                      <i class="text-success">
                        <font-awesome-icon :icon="['fas', 'check']" />
                      </i>
                    </h5>

                    <span class="label label-inline label-xl ml-2 label-light-success">
                      SW: {{ module.value || 'N/A' }}
                    </span>
                    <span v-if="module.ioBl" class="label label-inline label-xl ml-2 label-light-info">
                      BL: {{ module.ioBl || 'N/A' }}
                    </span>
                  </template>

                  <template v-else>
                    <h5 class="d-inline align-middle">
                      <i class="text-danger">
                        <font-awesome-icon :icon="['fas', 'times']" />
                      </i>
                    </h5>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end::Modules - Standard -->

        <!-- begin::Modules - Optional -->
        <div v-if="modules.optional && modules.optional.length" class="row mt-3 border-bottom">
          <div class="col-12 font-weight-bold mb-3">Options</div>
          <div
            v-for="module in modules.optional"
            :key="`equipment.${equipment._meta.guid}.log.${log._meta.guid}.module.secondary.${module.module}`"
            class="col-12 col-md-6 col-lg-4"
          >
            <div class="form-group">
              <label class="text-upper">{{ $tu(`luxura_logs.boot.modules.${module.module}.equipment.${equipment.model}.name`) || $tu(`luxura_logs.boot.modules.${module.module}.name`) || module.module }}</label>
              <div>
                <div class="overflow-ellipsis">
                  <template v-if="log.isModulePresent(module.module)">
                    <h5 class="d-inline align-middle">
                      <i class="text-success">
                        <font-awesome-icon :icon="['fas', 'check']" />
                      </i>
                    </h5>

                    <span class="label label-inline label-xl ml-2 label-light-success">
                      SW: {{ module.value || 'N/A' }}
                    </span>
                    <span v-if="module.ioBl" class="label label-inline label-xl ml-2 label-light-info">
                      BL: {{ module.ioBl || 'N/A' }}
                    </span>
                  </template>

                  <template v-else>
                    <h5 class="d-inline align-middle">
                      <i class="text-danger">
                        <font-awesome-icon :icon="['fas', 'times']" />
                      </i>
                    </h5>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end::Modules - Optional -->

        <!-- begin::Modules - Other -->
        <div v-if="otherModules && otherModules.length" class="row mt-3 border-bottom">
          <div class="col-12 font-weight-bold mb-3">Other</div>
          <div
            v-for="module in otherModules"
            :key="`equipment.${equipment._meta.guid}.log.${log._meta.guid}.module.secondary.${module.module}`"
            class="col-12 col-md-6 col-lg-4"
          >
            <div class="form-group">
              <label class="text-upper">{{ $tu(`luxura_logs.boot.modules.${module.module}.equipment.${equipment.model}.name`) || $tu(`luxura_logs.boot.modules.${module.module}.name`) || module.module }}</label>
              <div>
                <div class="overflow-ellipsis">
                  <template v-if="log.isModulePresent(module.module)">
                    <h5 class="d-inline align-middle">
                      <i class="text-success">
                        <font-awesome-icon :icon="['fas', 'check']" />
                      </i>
                    </h5>

                    <span class="label label-inline label-xl ml-2 label-light-success">
                      SW: {{ module.value || 'N/A' }}
                    </span>
                    <span v-if="module.ioBl" class="label label-inline label-xl ml-2 label-light-info">
                      BL: {{ module.ioBl || 'N/A' }}
                    </span>
                  </template>

                  <template v-else>
                    <h5 class="d-inline align-middle">
                      <i class="text-danger">
                        <font-awesome-icon :icon="['fas', 'times']" />
                      </i>
                    </h5>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end::Modules - Other -->

        <!-- begin::Additional -->
        <div class="row mt-3">
          <div class="col-12 font-weight-bold mb-3">Miscellaneous</div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <label class="text-upper">Line frequency</label>
              <div>
                <div class="overflow-ellipsis">
                  <h5 class="d-inline align-middle">
                    {{ `${log.json_data.frequency}Hz` }}
                  </h5>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <label class="text-upper">Phase configuration</label>
              <div>
                <div class="overflow-ellipsis">
                  <h5 class="d-inline align-middle">
                    {{ log.json_data.netConfig }}
                  </h5>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <label class="text-upper">Boot time</label>
              <div>
                <div class="overflow-ellipsis">
                  <h5 class="d-inline align-middle">
                    {{ `${log.json_data.bootTime}ms` }}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end::Additional -->
      </div>
    </div>
    <!-- end::Content -->
  </div>
</template>

<script>
import equipmentMixin from '@/components/pages/sunbeds/view/libs/mixin';
import { errorComponentMixin } from '@vedicium/core-vue';

import Equipment from '@/libs/classes/equipment';
import LuxuraLog from '@/libs/classes/luxura_log';
import LuxuraLogBoot from '@/libs/classes/luxura_log.boot';

import errorTranslationAlert from '@/components/errors/translation.alert.vue';

export default {
  mixins: [equipmentMixin, errorComponentMixin],
  components: {
    errorTranslationAlert,
  },
  data () {
    return {
      isLoading: false,
      isMounted: false,

      modules: {
        standard: [],
        optional: [],
      },

      log_options: [],
      log: null,
    };
  },
  computed: {
    otherModules () {
      if (!this.log) {
        return [];
      }

      const standardAndOptionalModules = [...this.modules.standard, ...this.modules.optional].map((row) => row.module, []);
      const excludedModules = ['myLuxuraCl'];
      return (this.log.modules || [])
        .filter((row) => this.log.isModulePresent(row.module), [])
        .filter((row) => standardAndOptionalModules.includes(row.module) === false, [])
        .filter((row) => excludedModules.includes(row.module) === false, []);
    },
  },

  async mounted () {
    this.$set(this, 'isLoading', true);
    try {
      // TODO: Use endpoint which gathers all options without json_data
      const logOptions = (await this.$ws.get(`${Equipment.uri}/${this.equipment._meta.guid}/logs`, {
        query: {
          sort: 'log_date|desc',
          filter: {
            type: 'boot',
          },
        },
      }) || []).map((log) => new LuxuraLogBoot().merge(log));
      this.$set(this, 'log_options', logOptions);

      // Get last log of options
      if (logOptions.length) {
        await this.selectLog(this.$route.query.log || logOptions[0]._meta.guid);
      }
    } catch (e) {
      console.error(e);
      this.$errors.handle(e, { component: this, ui_element: false });
    } finally {
      this.$set(this, 'isLoading', false);
    }

    await this.$nextTick();

    this.$set(this, 'isMounted', true);
  },

  methods: {
    async onLogSelect (guid = null) {
      this.$errors.clear({ component: this });
      this.$set(this, 'isLoading', true);
      try {
        await this.selectLog(guid);
      } catch (e) {
        console.error(e);
        this.$errors.handle(e, { component: this });
        return null;
      } finally {
        this.$set(this, 'isLoading', false);
      }

      return this.log;
    },
    async selectLog (guid = null) {
      if (!guid) {
        this.$set(this, 'secondaryModules', []);
        this.$set(this, 'log', null);
      }

      const log = new LuxuraLogBoot().merge(await this.$ws.get(`${Equipment.uri}/${this.equipment._meta.guid}/logs/${guid}`));
      this.$set(this, 'log', log);
      this.$set(this.modules, 'standard', log.getStandardModules(this.equipment));
      this.$set(this.modules, 'optional', log.getOptionalModules(this.equipment));

      // Set query log
      if (this.$route.query.log !== log._meta.guid) {
        this.$router.replace({
          ...this.$route,
          query: {
            ...(this.$route.query || {}),
            log: log._meta.guid,
          },
        });
      }

      return this.log;
    },

    async downloadLogFile (log = null, options = {}) {
      if (!log || log instanceof LuxuraLog === false) {
        return;
      }

      this.$set(this, 'isLoading', true);
      try {
        await log.download(options);
      } catch (e) {
        this.$errors.handle(e, { ui_element: true });
        console.error(e);
        return;
      } finally {
        this.$set(this, 'isLoading', false);
      }
    },

    selectNextLog () {
      const logIdx = (this.log_options || []).findIndex((log) => log._meta.guid === this.log._meta.guid);
      if (logIdx === -1 || logIdx === 0) {
        return;
      }
      this.onLogSelect(this.log_options[logIdx - 1]._meta.guid);
    },
    selectPreviousLog () {
      const logIdx = (this.log_options || []).findIndex((log) => log._meta.guid === this.log._meta.guid);
      if (logIdx === -1 || logIdx === (this.log_options.length - 1)) {
        return;
      }
      this.onLogSelect(this.log_options[logIdx + 1]._meta.guid);
    },
  },
};
</script>
