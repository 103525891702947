/* eslint-disable camelcase */
/* eslint-disable lines-between-class-members */

import LuxuraLog from '@/libs/classes/luxura_log';
import Equipment from '@/libs/classes/equipment';

export default class LuxuraLogBoot extends LuxuraLog {
  modules = [];

  // Abstract methods
  merge (body = {}) {
    super.merge(body);

    // Save modules in a seperate column
    this.modules = this.getModules() || [];

    return this;
  }

  // Methods
  getModules () {
    const modules = [];

    // Add display as a module
    modules.push({
      module: 'display',
      value: this.json_data.displayBoardSoftware,
      ioBl: this.json_data.displayBoardBootloader,
      serial: this.json_data.serialDisplay,
    });

    // Add display files as a module
    modules.push({
      module: 'displayFiles',
      value: this.json_data.displayFiles,
      ioBl: null,
      serial: null,
    });

    // Add display touch as a module
    modules.push({
      module: 'displayTouch',
      value: this.json_data.displayTouchSoftware,
      ioBl: this.json_data.displayTouchBootloader,
      serial: null,
    });

    // Add switchboard as module
    // But only add it if the module 'mainIc' is not found.
    if (
      this.json_data
      && this.json_data.modules
      && this.json_data.modules.mainIc
      && this.json_data.modules.mainIc.value
      && this.json_data.modules.mainIc.value.length === 0
    ) {
      modules.push({
        module: 'switchboard',
        value: this.json_data.switchBoardSoftware,
        ioBl: null,
        serial: this.json_data.serialSwitchBoard,
      });
    }

    if (this.json_data && this.json_data.modules) {
      const bootModules = Object.keys(this.json_data.modules).map((module) => ({
        module,
        ...(this.json_data.modules[module] || {}),
      }));

      modules.push(...(bootModules || []));
    }

    return modules;
  }

  getStandardModules (equipment = null) {
    const standardModules = ['display', 'displayFiles', 'displayTouch'];

    if (equipment instanceof Equipment) {
      switch (equipment.model) {
        // Standard modules for Luxura Jewel
        case 'luxura_jewel': {
          standardModules.push('mainIc', 'sensIc', 'fanModule1', 'fanModule2', 'rgbModule', 'ipcModule1', 'ipcModule2', 'driver1Module1', 'driver1Module2', 'driver1Module3', 'driver1Module4');
          break;
        }

        // Standard modules for Luxura Vegaz 9200 & Luxura Vegaz 8200
        case 'luxura_vegaz_9200':
        case 'luxura_vegaz_8200': {
          standardModules.push('switchboard', 'fanModule1', 'fanModule2', 'rgbModule', 'gbFanModule');
          break;
        }

        // Standard modules for Luxura X7 & Luxura X5
        case 'luxura_x7':
        case 'luxura_x5': {
          standardModules.push('switchboard', 'fanModule1', 'rgbModule');
          break;
        }

        // Standard modules for Luxura V8 & Luxura V6
        case 'luxura_v8':
        case 'luxura_v6': {
          standardModules.push('switchboard', 'rgbModule');
          break;
        }

        default: {
          break;
        }
      }
    }

    return this.modules.filter((module) => standardModules.includes(module.module), []);
  }

  getOptionalModules (equipment = null) {
    // The optional modules are based on equipment
    const optionalModules = [];

    if (equipment instanceof Equipment) {
      // Options based on model
      switch (equipment.model) {
        case 'luxura_jewel': {
          // Sens option
          if (equipment.specifications.options.includes('Sens')) {
            optionalModules.push('qsensModule', 'xsensModule');
          }

          // Climate control
          if (equipment.specifications.options.includes('Climate Control')) {
            optionalModules.push('climateControl', 'aircoModule');
          }

          // SoundAround Plus
          if (equipment.specifications.options.includes('SoundAround Plus')) {
            optionalModules.push('audioModule');
          }

          break;
        }

        case 'luxura_vegaz_9200':
        case 'luxura_vegaz_8200': {
          // Climate control
          if (equipment.specifications.options.includes('Climate Control')) {
            optionalModules.push('climateControl', 'aircoModule');
          }

          // Qsens & Xsens
          if (equipment.specifications.options.includes('Qsens & Xsens III')) {
            optionalModules.push('qsensModule', 'xsensModule');
          }

          // Heat module & soundaround plus
          if (equipment.specifications.options.includes('Shoulder tanner + SoundAround Plus')) {
            optionalModules.push('heatModule', 'audioModule', 'btModule');
          }

          // IPcII modules
          if (['9200_intelligent', '9200_highbrid', '8200_intelligent', '8200_highbrid'].includes(equipment.type)) {
            optionalModules.push('ipcModule1', 'ipcModule2');
          }

          // UV LED tanning booster
          if (equipment.specifications.options.includes('UV-LED tanning booster')) {
            optionalModules.push('uvLed20Module1', 'uvLed20Module2', 'uvLed30Module1', 'uvLed30Module2', 'uvLed30V2Module1', 'uvLed30V2Module2');
          }

          break;
        }

        case 'luxura_x7':
        case 'luxura_x5': {
          // Qsens
          if (equipment.specifications.options.includes('Qsens')) {
            optionalModules.push('qsensModule');
          }

          // Xsens
          if (equipment.specifications.options.includes('Xsens III')) {
            optionalModules.push('xsensModule');
          }

          // Air conditioning
          if (equipment.specifications.options.includes('Airco')) {
            optionalModules.push('aircoModule');
          }

          // SoundAround Plus
          if (equipment.specifications.options.includes('SoundAround Plus')) {
            optionalModules.push('audioModule', 'btModule');
          }

          // IPcII modules
          if (['luxura_x7'].includes(equipment.model) && ['38_highbrid', '38_sli_intelligent', '42_highbrid', '42_sli_intelligent'].includes(equipment.type)) {
            optionalModules.push('ipcModule1');
          }

          break;
        }

        case 'luxura_v8':
        case 'luxura_v6': {
          // Qsens
          if (equipment.specifications.options.includes('Qsens')) {
            optionalModules.push('qsensModule');
          }

          // Xsens
          if (equipment.specifications.options.includes('Xsens')) {
            optionalModules.push('xsensModule');
          }

          // SoundAround Plus
          if (equipment.specifications.options.includes('SoundAround Plus')) {
            optionalModules.push('audioModule', 'btModule');
          }

          // IPcII modules
          if (['luxura_v8'].includes(equipment.model) && ['48xl_intelligent', '48xl_highbrid'].includes(equipment.type)) {
            optionalModules.push('ipcModule1');
          }

          break;
        }

        default:
          break;
      }
    }

    const standardModules = (this.getStandardModules(equipment) || []).map((row) => row.module);
    return this.modules.filter((row) => optionalModules.includes(row.module), []).filter((row) => standardModules.includes(row.module) === false, []);
  }

  getModule (module = null) {
    if (!module || !this.json_data) {
      return null;
    }

    return this.modules.find((row) => row.module === module);
  }

  isModulePresent (module = null) {
    const moduleDetails = this.getModule(module);
    if (!moduleDetails || !moduleDetails.value) {
      return false;
    }
    return true;
  }
}
