/* eslint-disable camelcase */
/* eslint-disable lines-between-class-members */
import core from '@vedicium/core-vue';

import Abstract from '@/libs/classes/abstract';
import Equipment from '@/libs/classes/equipment';

export default class LuxuraLog extends Abstract {
  static uri = '/v2/luxura_logs';

  // Scheme
  device_guid = null;
  equipment_guid = null;

  type = null;
  log_id = null;
  controller_serial = null;

  log_date = null;
  json_data = null;
  raw_data = null;

  download (options = {}) {
    return core.http.get(`${Equipment.uri}/${this.equipment_guid}/logs/${this._meta.guid}/download`, { ...options });
  }
}
