<template>
  <blank-error
    v-bind="$props"
    class="mb-0"
    :title="translation.title"
    variant="light-danger"
  >
    <p class="mb-0" v-html="translation.message" />
 </blank-error>
</template>

<script>
import { errorHandling } from '@vedicium/core-vue';
import { BlankError } from '@vedicium/metronic-vue';

export default {
  props: {
    error: {
      type: Error,
      default: null,
    },
    variant: {
      type: String,
      default: 'light-danger',
    },
  },
  components: {
    BlankError,
  },
  computed: {
    convertedError () {
      return errorHandling.convert(this.error);
    },
    translation () {
      const convertedError = errorHandling.convert(this.error);

      const translation = {
        path: convertedError.i18n.path,
        title: this.$tu('errors.fallback.title', convertedError) || convertedError.error,
        message: this.$tu('errors.fallback.message', convertedError) || convertedError.message,
      };

      translation.title = this.$tu(`${translation.path}.title`, convertedError) || translation.title;
      translation.message = this.$tu(`${translation.path}.message`, convertedError) || translation.message;

      return translation;
    },
  },
};
</script>
